import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DataState from "ContextAPI/DataContext";


const LandingPagev2_EmailWarmup_Consultant = lazy(() => import("./Pages/LandingPagev2_EmailWarmup_Consultant"))
const LandingPagesv2_EmailWarmup_API_Service = lazy(() => import("./Pages/LandingPagev2_EmailWarmup_API_Service"))
const LoadingPagev1 = lazy(() => import("../src/Pages/LoadingPagev1"))
const Redirect = lazy(() => import("../src/Pages/Redirect"))



const ProjectRoutes = () => {

  return (
    <DataState>
      <Suspense fallback={<>Loading...</>}>
        <Router>
          <Routes>

            <Route
              path="/"
              element={
                <Suspense fallback={<LandingPagesv2_EmailWarmup_API_Service />}>
                  <LandingPagesv2_EmailWarmup_API_Service />
                </Suspense>
              }
            />
            <Route
              path="/warm-up"
              element={
                <Suspense fallback={<LoadingPagev1 />}>
                  <LoadingPagev1 />
                </Suspense>
              }
            />
            <Route
              path="/dashboard"
              element={<Redirect />}
            />


            {/* https://calendly.com/hello-emaildeliverabilityconsultant/freeconsultation */}


          </Routes>
        </Router>
      </Suspense>
    </DataState>
  );
};
export default ProjectRoutes;
